import getContract from "@/utils/getContract";


function judgeToken(rootState) {
    if (!state.token) state.token = getContract.getContractByName("Bridge", rootState.web3);
}

const state = {
    token: undefined,
};
const mutations = {

};
const actions = {

    calculatedFees({ rootState }, {destinationChainSelector,amount} ) {

        judgeToken(rootState);

        return new Promise((resolve, reject) => {
            state.token.methods
                .calculatedFees(destinationChainSelector,amount)
                .call()
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    moveToChain({ rootState }, {destinationChainSelector,amount,value} ) {
        judgeToken(rootState);
        return new Promise((resolve, reject) => {
            state.token.methods
                .moveToChain(destinationChainSelector,amount)
                .estimateGas({
                    from: rootState.account,
                    value
                })
                .then((gas) => {
                    state.token.methods
                        .moveToChain(destinationChainSelector,amount)
                        .send({
                            from: rootState.account,
                            gas: parseInt(gas * 1.2),
                            value
                        })
                        .then((res) => {
                            resolve(res);
                        })
                        .catch((err) => {
                            reject(err);
                        });
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },


};
export default {
    namespaced: true,
    mutations,
    state,
    actions,
};

