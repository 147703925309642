import BigNumber from "bignumber.js";
const MaxUint256 = BigNumber('0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff').toFixed(0);
const EtherScanMap = {
    ETH:"https://sepolia.etherscan.io/",
    BSC:"https://testnet.bscscan.com/"
}
const ETHDecimal = 18
// const CLH = '0xcB27F71C21A7853525E499bc0B662aA038017881';
//env:mainnet
const BridgeAddr = '0x6A8d2E7e0122ceA078c1417bD38F0B720Eab0720';
const CLH = '0x5Fc67Bc152D074B48b777c41D16371Cdb0cBF674';
const ChainIdMap={
    "ETH":"11155111",
    "BSC":"97",
}
const Network = {
    '0x61': {
        chainId: '0x61',
        chainName: 'BNB Smart Chain Testnet',
        nativeCurrency: {
            name: 'tBNB',
            symbol: 'tBNB',
            decimals: 18,
        },
        rpcUrls: ['https://endpoints.omniatech.io/v1/bsc/testnet/public'],
        blockExplorerUrls: ['https://testnet.bscscan.com'],
    },
    '0xaa36a7': {
        chainId: '0xaa36a7',
        chainName: 'ETH',
        nativeCurrency: {
            name: 'ETH',
            symbol: 'ETH',
            decimals: 18,
        },
    },
};
export { BridgeAddr, Network, CLH,ETHDecimal,ChainIdMap,MaxUint256,EtherScanMap };
