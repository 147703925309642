<template>
  <header>
    <div class="stake-logo">
      <a href="/">
        <img  src="~@/assets/images/logo.png" alt="Clear" />
      </a>
    </div>
    <wallet></wallet>
  </header>
  <router-view/>
  <footerMobile v-if="isMobile"/>
  <footerDesktop v-if="!isMobile"/>
</template>
<script>
import footerDesktop from "@/components/footerDesktop.vue";
import footerMobile from "@/components/footerMobile.vue";

import wallet from "@/components/wallet.vue";
import {mapState} from "vuex";
import { mobileRem } from "@/common/rem";
export default {
  components:{footerDesktop,footerMobile,wallet},
  computed:{
    ...mapState({
      isMobile: (state) => state.isMobile,
      account: (state) => state.account,
      walletBalance: (state) => state.userBalance,
      chainId: (state) => state.chainId,
      desireChainId: (state) => state.desireChainId,
    }),
  },
  created() {
    mobileRem();
  }
}
</script>
<style lang="scss">
header {
  padding: 52px 7.5% 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .stake-logo {
    display: flex;
    align-items: center;
    img{
      width: 160px;
    }
  }
}
@media screen and (min-width: 820px) and (max-width: 1080px) {
  .stake-logo {
    header {
      padding: 52px 3.67% 40px;
    }
    img{
      width: 100px;
    }
  }
}
@media screen and (min-width: 1080px) and (max-width: 1280px) {
  .stake-logo {
    header {
      padding: 52px 4% 40px;
    }
  }
}
@media screen and (min-width: 1280px) and (max-width: 1600px) {
  .stake-logo {
    header {
      padding: 52px 6% 40px;
    }
  }
}
@media screen and (max-width: 820px) {
  .stake-logo {
    img{
      width: 90px!important;
    }

  }
  header {
    padding: 20px 5% 20px;

  }
  .title{
    text-align: center;
    padding-bottom: 30px;
  }
}
</style>
