<!--  -->
<template>
  <div class="flex-box" :class="{ 'fairbet-wallet-mobile': device == 'mobile' }">
    <!-- moblie -->
    <template v-if="isMobile">
      <template v-if="account">
        <a-popover placement="bottomRight">
          <a-button :loading="connectedLoading">
            {{ showAddress(w3Account) }}
          </a-button>
        </a-popover>
      </template>
      <a-button class="connection" v-else @click="w3mConnect" :loading="connectedLoading"> Connect Wallet</a-button>
    </template>
    <template v-else>
      <div class="current" v-if="chainId == ChainIdMap['ETH']">
        <img width="22" style="margin-right: 10px" src="@/assets/images/eth.svg" alt="" />
        ETH
      </div>
      <div class="current" v-else>
        <img width="22" style="margin-right: 10px" src="@/assets/images/bsc.svg" alt="" />
        BSC
      </div>
      <template v-if="w3Account">
        <a-button :loading="connectedLoading" @click="w3mConnect">
          {{ showAddress(w3Account) }}
        </a-button>
      </template>
      <!--      connect-->
      <a-button class="connection" v-else @click="w3mConnect" :loading="connectedLoading"> Connect Wallet</a-button>
    </template>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { Network } from '@/config/constants';
import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum';
import { Web3Modal } from '@web3modal/html';
import { configureChains, createConfig } from '@wagmi/core';
import { sepolia, bscTestnet } from '@wagmi/core/chains';
import { ChainIdMap } from '@/config/constants';
import Web3 from 'web3';
const chains = [bscTestnet, sepolia];
const projectId = process.env.VUE_APP_ProjectId;
import { getAccount, watchAccount, watchNetwork } from '@wagmi/core';
const { publicClient } = configureChains(chains, [w3mProvider({ projectId })]);
const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, chains }),
  publicClient,
});
const ethereumClient = new EthereumClient(wagmiConfig, chains);
const web3modal = new Web3Modal({ projectId }, ethereumClient);
export default {
  name: 'WalletCom',
  components: {},
  data() {
    return {
      ChainIdMap,
      w3Account: '',
      drawerVisible: false,
      connectedLoading: false,
      networkObj: Network,
      downVisible: false,
    };
  },

  computed: {
    isOnRightChain() {
      if (this.chainId == this.desireChainId) {
        return true;
      }
      return false;
    },
    ...mapState({
      chainId: (state) => state.chainId,
      desireChainId: (state) => state.desireChainId,
      account: (state) => state.account,
      isMobile: (state) => state.isMobile,
    }),
  },
  watch: {
    desireChainId(val) {
      if (val == ChainIdMap['BSC']) {
        this.switchNetwork(parseInt(ChainIdMap['BSC']).toString(16));
      } else {
        this.switchNetwork(parseInt(ChainIdMap['ETH']).toString(16));
      }
    },
    chainId() {
      if (this.chainId == ChainIdMap['BSC']) {
        this.$store.commit('SET_DesireChain', this.chainId);
        web3modal.setDefaultChain(bscTestnet);
      }
      if (this.chainId == ChainIdMap['ETH']) {
        this.$store.commit('SET_DesireChain', this.chainId);
        web3modal.setDefaultChain(sepolia);
      }
    },
  },
  methods: {
    async switchNetwork(value) {
      value = '0x' + value;
      try {
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: value }],
        });

        this.showChooseChain = false;
      } catch (switchError) {
        // This error code indicates that the chain has not been added to MetaMask.
        try {
          let paramsArry = [
            {
              chainId: this.networkObj[value].chainId,
              chainName: this.networkObj[value].chainName,
              rpcUrls: this.networkObj[value].rpcUrls,
              nativeCurrency: this.networkObj[value].nativeCurrency,
              blockExplorerUrls: this.networkObj[value].blockExplorerUrls ? this.networkObj[value].blockExplorerUrls : null,
            },
          ];
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: paramsArry,
          });
        } catch (e) {
          console.log(e);
          location.reload();
        }
      }
    },
    showAddress: function (text) {
      return text.substring(0, 6) + '...' + text.substring(38, 42);
    },

    ...mapActions([]),
    w3mConnect() {
      web3modal.openModal();
    },
    async getCon() {
      if (window.ethereum) {
        window.ethereum
          .request({ method: 'eth_accounts' })
          .then((accounts) => {
            if (accounts && accounts.length > 0) {
              this.$store.commit('SET_Web3', new Web3(window.ethereum));
              this.$store.commit('SET_ACCOUNT', accounts[0]);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
      watchAccount(async (account) => {
        this.w3Account = account.address;
        this.$store.commit('SET_Web3', new Web3(window.ethereum));
        this.$store.commit('SET_ACCOUNT', account.address);
      });
      watchNetwork((network) => {
        if (network && network.chain) {
          this.$store.commit('SET_Chain', network.chain.id);
        }
      });
    },

    connect() {},
  },
  async created() {
    const account = getAccount();
    this.w3Account = account.address;
    this.$store.commit('Set_Web3modal', web3modal);
    this.getCon();
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.current {
  width: 210px;
  height: 56px;
  border-radius: 5px 5px 5px 5px;
  opacity: 1;
  margin-right: 40px;
  border: 1px solid #d6d8e1;
  color: #333333;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
:deep(.ant-btn) {
  &:hover,
  &:focus,
  &:active {
    span {
      color: white;
    }
  }
}
button {
  width: 210px;
  height: 56px;
  color: white;
  background: #0e1d51;
  font-size: 20px;
  font-family: AvertaStd-Regular;
  border: none;
  &:hover,
  &:focus,
  &:active {
    background: linear-gradient(90deg, #7b7cf2 0%, #2dcaed 100%);
  }
  &.active-button {
    background: linear-gradient(90deg, #7b7cf2 0%, #2dcaed 100%);
    color: white;
  }
}
@media screen and (max-width: 820px) {
  button{
    width: 150px;
    height: 40px;
    font-size: 16px;
  }

}
.change-network {
  margin-right: 40px;
  width: 204px;
  height: 56px;
  font-size: 20px;
  border: none;
  color: #111111;
  .ant-select-selection--single {
    height: 56px;
    border: 1px solid rgba(213, 216, 227, 1);
    border-radius: 4px;
    background: transparent;
    .ant-select-selection__rendered {
      line-height: 54px;
      margin-left: 20px;
      margin-right: 40px;
      display: flex;
      justify-content: center;
    }

    .ant-select-arrow {
      right: 20px;
      color: #111111;
      display: none;
    }
  }
}
.select-chain {
  width: auto !important;
  margin-right: 10px;
  padding: 0 20px;

  .dot {
    margin-left: 10px;
    width: 8px;
    height: 8px;
    background: #d8d8d8;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    > div {
      width: 4px;
      height: 4px;
      border-radius: 50%;
    }

    &.active {
      > div {
        background: #52df9b;
      }
    }
  }
}

.header-more-menu {
  min-width: 196px;
  top: 96px !important;
}

.mobile-menu {
  top: 1.22rem;

  .ant-drawer-content {
    .ant-drawer-body {
      padding: 0;
    }
  }
}
</style>
