import { createApp } from 'vue'
import App from './App.vue'
// import './registerServiceWorker'
import router from './router'
import store from './store'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/reset.css';
import "./assets/css/commons.scss";
import './assets/css/index.scss';
createApp(App).use(store).use(Antd).use(router).mount('#app')
