import {BridgeAddr} from "@/config/constants"

const CONTRACTS = {
    Bridge: {
        abi: require("@/abi/Bridge.json"),
        address: BridgeAddr
    },
    erc20: {
        abi: require("@/abi/erc20.json"),
    }
};

function getContractByToken(name, address, web3) {

    if(!web3||!web3.eth||!web3.eth.Contract){
        return
    }
    return new web3.eth.Contract(CONTRACTS[name].abi, address, {});
}

function getContractByName(name, web3) {
    if(!web3||!web3.eth||!web3.eth.Contract){
        return
    }
    return new web3.eth.Contract(CONTRACTS[name].abi, CONTRACTS[name].address, {});
}

export default {
    getContractByName,
    getContractByToken
};
