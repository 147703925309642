<template>
  <div class="TokenMove">
    <div class="history-dialog" v-show="isShowHistory">
      <div class="mask" @click="isShowHistory = false"></div>
      <div class="history-dialog-content">
        <div class="title">
          History
          <img @click="isShowHistory = false" width="26" height="26" src="@/assets/images/close.png" alt="" />
        </div>
        <div class="table">
          <div class="header">
            <div class="col">Amount</div>
            <div class="col">Fees</div>
            <div class="col">Current Chain</div>
            <div class="col">Target Chain</div>
            <div class="col">Time</div>
          </div>
          <div class="row-box">
            <div class="row-item" v-for="(item, index) in historyArr" :key="index">
              <div class="col">
                <img width="16" style="margin-right: 5px" src="@/assets/images/logo_m.png" alt="" />
                {{ BigNumber(item.amount).toFixed(2) }} CLH
              </div>
              <div class="col">
                {{ BigNumber(item.fees).toFixed(6) }}
              </div>
              <div class="col">
                <a target="_blank" :href="EtherScanMap[getChainName(item.from_chain)] + '/tx/' + item.from_trans_hash">{{ getChainName(item.from_chain) }}</a>
              </div>
              <div class="col">
                <a v-if="item.to_trans_hash" target="_blank" :href="EtherScanMap[getChainName(item.to_chain)] + '/tx/' + item.to_trans_hash">{{ getChainName(item.to_chain) }}</a>
                <span v-else>
                  {{ getChainName(item.to_chain) }}
                </span>
              </div>
              <div class="col">
                {{ item.updated_at }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="title">CLH Bridge</div>
    <div class="info">
      Send your assets between layers using the Bridge. This transaction can take up to 15 minutes.

      <a href="https://chain.link/cross-chain" target="_blank">Secured with Chainlink CCIP.</a>
    </div>
    <div class="send-box">
      <div class="send-header">
        <div class="left">Token</div>
        <div class="right">
          <img width="22" @click="isShowHistory = true" style="margin-right: 10px; cursor: pointer" src="@/assets/images/history.svg" alt="" />

          <img src="@/assets/images/logo_m.png" alt="" class="logo" />
          CLH
        </div>
      </div>
      <div class="operate-part">
        <div class="input-header">
          <div class="left">From</div>
          <div class="right">Balance: {{ balance }}</div>
        </div>
        <div class="input-box">
          <a-select v-model:value="selectChain" @select="handleSelect" style="width: 120px">
            <a-select-option value="ETH">
              <img width="16" style="margin-top: -2px" src="@/assets/images/eth.svg" alt="" />
              ETH
            </a-select-option>
            <a-select-option value="BSC">
              <img width="16" style="margin-top: -2px" src="@/assets/images/bsc.svg" alt="" />
              BSC
            </a-select-option>
          </a-select>
          <input type="text" v-model="amount" placeholder="0" />
          <div class="set-max" @click="setMax">MAX</div>
        </div>
      </div>
      <img width="26" height="26" @click="transfer" class="transfer" src="@/assets/images/transfer.svg" alt="" />
      <div class="operate-part" style="margin-top: 50px">
        <div class="input-header">
          <div class="left">To</div>
          <div class="right"></div>
        </div>
        <div class="input-box">
          <a-select v-model:value="desireChain" @select="handleSelectDesir" style="width: 120px">
            <a-select-option value="ETH">
              <img width="16" style="margin-top: -2px" src="@/assets/images/eth.svg" alt="" />
              ETH
            </a-select-option>
            <a-select-option value="BSC">
              <img width="16" style="margin-top: -2px" src="@/assets/images/bsc.svg" alt="" />
              BSC
            </a-select-option>
          </a-select>
          <input type="text" v-model="amount" placeholder="0" style="background: none" />
        </div>
      </div>
      <a-button class="submit" v-if="!isOnRightChain" @click="connect"> Switch right chain </a-button>
      <a-button class="submit" v-else-if="!account" @click="connect"> Connect wallet </a-button>
      <a-button class="submit" v-else-if="BigNumber(balance).lt(amount)" disabled> Excess balance </a-button>
      <template v-else>
        <a-button v-if="BigNumber(allowanceNum).lt(amount)" :loading="isLoading" class="submit" @click="handleMove"> Approve && Send Tokens </a-button>
        <a-button v-else :loading="isLoading" class="submit" @click="handleMove"> Send Tokens </a-button>
      </template>

      <div class="info-row">
        <div class="left">Gas Fee</div>
        <div class="right">≈{{ fee.toFixed(6) }} ETH</div>
      </div>
      <div class="info-row">
        <div class="left">Time Spend</div>
        <div class="right">10～15minutes</div>
      </div>
    </div>
    <div class="progress">
      <div v-show="isLoading">
        Waiting for Current Chain Confirmation
        <img src="@/assets/images/loading.svg" alt="" />
      </div>
      <div v-show="isWaiting">
        Waiting for Target Chain Confirmation
        <img src="@/assets/images/loading.svg" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { getTokenMoveInfo, getMyTokenMoveHistory } from '@/api/tokenMove';
import { ETHDecimal, CLH, ChainIdMap, BridgeAddr, MaxUint256, EtherScanMap } from '@/config/constants';
import BigNumber from 'bignumber.js';

import { message } from 'ant-design-vue';
import { getMyTokenMoveLast } from '@/api/tokenMove';
import Cookies from 'js-cookie';

let timeOut;
export default {
  name: 'TokenMove',
  components: {},
  computed: {
    ...mapState({
      isMobile: (state) => state.isMobile,
      account: (state) => state.account,
      walletBalance: (state) => state.userBalance,
      chainId: (state) => state.chainId,
      desireChainId: (state) => state.desireChainId,
    }),
    isOnRightChain() {
      if (this.chainId == this.desireChainId) {
        return true;
      }
      return false;
    },
  },
  data() {
    return {
      EtherScanMap,
      BigNumber,
      amount: undefined,
      isWaiting: false,
      isLoading: false,
      historyArr: [],
      selectChain: 'ETH',
      desireChain: 'BSC',
      configArr: [],
      fee: 0,
      isShowHistory: false,
      balance: 0,
      allowanceNum: 0,
      decimals: 18,
    };
  },
  watch: {
    amount() {
      clearTimeout(timeOut);
      timeOut = setTimeout(() => {
        if (BigNumber(this.balance).lt(this.amount)) {
          this.setMax();
        }
      }, 1000);
    },
    account() {
      this.getData();
    },

    chainId() {
      if (this.chainId == ChainIdMap['BSC']) {
        this.selectChain = 'BSC';
        this.desireChain = 'ETH';
      } else {
        this.selectChain = 'ETH';
        this.desireChain = 'BSC';
      }
      this.getData();
    },
  },
  methods: {
    getChainName(id) {
      if (id == 1) {
        return 'ETH';
      } else {
        return 'BSC';
      }
    },
    transfer() {
      if (this.desireChain == 'BSC') {
        this.desireChain = 'ETH';
      } else {
        this.desireChain = 'BSC';
      }
      this.handleSelectDesir();
    },
    connect() {
      if (this.$store.state.web3model) {
        this.$store.state.web3model.openModal();
      }
    },
    async getRes(hash) {
      const res = await getMyTokenMoveLast({
        trans_hash: hash,
      });
      console.log(res);
      if (res.code == 0 && res.data && res.data.to_trans_hash) {
        message.success('Transition success on desire chain');
        Cookies.set('hash', '');
        this.isWaiting = false;
      } else {
        setTimeout(() => {
          this.getRes(hash);
        }, 3000);
      }
      console.log(res);
    },
    handleSelect() {
      this.$store.commit('SET_DesireChain', ChainIdMap[this.selectChain]);

      if (this.selectChain == 'BSC') {
        this.desireChain = 'ETH';
      } else {
        this.desireChain = 'BSC';
      }
    },
    handleSelectDesir() {
      if (this.desireChain == 'BSC') {
        this.selectChain = 'ETH';
      } else {
        this.selectChain = 'BSC';
      }
      this.$store.commit('SET_DesireChain', ChainIdMap[this.selectChain]);
    },
    async handleMove() {
      if (!this.amount) {
        return;
      }
      this.isLoading = true;
      const amount = BigNumber(this.amount.toString()).multipliedBy(BigNumber(10).pow(this.decimals));
      if (BigNumber(this.allowanceNum.toString()).lt(amount)) {
        const isP = await this.approve();
        if (!isP) {
          return;
        }
      }
      let chainSelector = this.configArr[1].chainSelector;
      if (this.selectChain == 'BSC') {
        chainSelector = this.configArr[0].chainSelector;
      }
      this.$store
        .dispatch('Bridge/moveToChain', {
          destinationChainSelector: chainSelector,
          amount: amount.toFixed(0).toString(),
          value: BigNumber(this.fee.toString())
            .multipliedBy(10 ** ETHDecimal)
            .toFixed(0)
            .toString(),
        })
        .then((res) => {
          this.dealRes(res.transactionHash);
          return true;
        })
        .catch((e) => {
          this.dealCatchErr(e);
          console.log(e);
          return false;
        });
    },
    setMax() {
      this.amount = this.balance;
    },
    async dealRes(hash) {
      // let statusRes = await getTranStatus(transactionHash);
      this.isWaiting = true;
      Cookies.set('hash', hash);
      this.getRes(hash);
      this.isLoading = false;
      this.getData();
      message.success('Transaction Success');
    },
    dealCatchErr(e) {
      this.isLoading = false;
      if (e.code === 4001) {
        message.error('User canceled  transaction');
        return;
      }
      if (e.message && e.message.indexOf('{') < 0) {
        message.error(e.message);
      } else {
        const err = JSON.parse(e.message.substr(e.message.indexOf('{'), e.message.length));
        if (err.originalError) {
          message.error(err.originalError.message);
        } else {
          message.error(e.message.substr(0, e.message.indexOf('{')));
        }
      }
    },
    async allowance() {
      let res = await this.$store.dispatch('erc20/allowance', {
        owner: this.account,
        spender: BridgeAddr,
        address: CLH,
      });
      this.allowanceNum = res;
    },
    async approve() {
      return this.$store
        .dispatch('erc20/approve', { spender: BridgeAddr, address: CLH, amount: MaxUint256 })
        .then(() => {
          this.allowance();
          return true;
        })
        .catch((e) => {
          this.dealCatchErr(e);
          console.log(e);
          return false;
        });
    },
    async getBalance() {
      const decimal = await this.$store.dispatch('erc20/decimals', {
        address: CLH,
      });
      this.decimals = decimal;
      const balance = await this.$store.dispatch('erc20/balanceOf', {
        account: this.account,
        address: CLH,
      });
      this.balance = BigNumber(balance.toString()).div(10 ** decimal.toString());
    },
    async getData() {
      const res = await getTokenMoveInfo();

      if (!this.chainId) {
        return;
      }

      if (!this.account) {
        return;
      }
      const history = await getMyTokenMoveHistory({
        address: this.account,
      });
      if (history.code == 0) {
        this.historyArr = history.data;
      }
      if (!this.isOnRightChain) {
        return;
      }
      console.log(history);

      if (res && res.code == 0) {
        this.configArr = res.data;
        let chainSelector = this.configArr[1].chainSelector;
        if (this.chainId == ChainIdMap['BSC']) {
          chainSelector = this.configArr[0].chainSelector;
          this.selectChain = 'BSC';
          this.desireChain = 'ETH';
        }
        this.getBalance();
        this.allowance();
        const fee = await this.$store.dispatch('Bridge/calculatedFees', {
          destinationChainSelector: chainSelector,
          amount: 1,
        });
        this.fee = BigNumber(fee.toString()).div(10 ** ETHDecimal);
      }
    },
  },
  created() {
    this.getData();

    if (this.chainId == ChainIdMap['BSC']) {
      this.selectChain = 'BSC';
      this.desireChain = 'ETH';
    } else {
      this.selectChain = 'ETH';
      this.desireChain = 'BSC';
    }
    const hash = Cookies.get('hash');
    if (hash) {
      this.isWaiting = true;
      this.getRes(hash);
    }
  },
};
</script>

<style lang="scss" scoped>
.history-dialog {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1;

  .mask {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.2);
  }

  .history-dialog-content {
    position: absolute;
    left: calc(50% - 450px);
    padding: 30px;
    top: calc(50% - 300px);
    z-index: 1;
    width: 900px;
    height: 510px;
    background: #ffffff;
    border-radius: 5px 5px 5px 5px;
    border: 1px solid #dedede;
    box-shadow: 10px 10px 0px #e4e7f9;

    .title {
      font-size: 24px;
      font-family: Roboto-SemiBold, Roboto;
      font-weight: 600;
      color: #000000;
      text-align: center;
      margin-bottom: 30px;
      position: relative;

      img {
        position: absolute;
        right: 20px;
        cursor: pointer;
      }
    }

    .header {
      height: 50px;
      background: #e6e9fb;
      display: flex;
      border-radius: 5px 5px 5px 5px;
    }

    .col {
      font-size: 14px;
      font-family: Roboto-SemiBold, Roboto;
      font-weight: 600;
      color: #000000;
      width: 20%;
      display: flex;
      align-items: center;
      padding-left: 20px;
    }

    .row-item {
      display: flex;
      height: 50px;

      &:nth-child(n + 2) {
        border-top: 0;
      }
    }

    .row-box {
      height: 360px;
      overflow: auto;
    }
  }
}

.TokenMove {
  padding-top: 20px;
  padding-bottom: 50px;
  width: 600px;
  margin: 0 auto;

  .title {
    font-size: 26px;
    font-family: Roboto-SemiBold, Roboto;
    font-weight: 600;
  }

  .progress {
    text-align: center;
    height: 60px;
    font-size: 16px;
  }

  .info {
    font-size: 18px;
    font-family: Roboto-Medium, Roboto;
    font-weight: 500;
    color: #666666;
    margin-top: 10px;
  }

  .send-box {
    box-shadow: 10px 10px 0px #e4e7f9;
    margin: 30px auto;
    width: 420px;
    //background: #FFFFFF;
    border-radius: 5px 5px 5px 5px;
    border: 1px solid #727272;
    background: #ffffff;

    padding: 30px 20px;

    .submit {
      width: 380px;
      height: 50px;
      background: linear-gradient(90deg, #7d80e3 0%, #7dc5e5 100%);
      border-radius: 5px 5px 5px 5px;
      margin: 20px auto;
      font-size: 16px;
      font-family: Roboto-SemiBold, Roboto;
      font-weight: 600;
      color: #ffffff;
    }

    .send-header {
      display: flex;
      justify-content: space-between;
      font-size: 20px;
      font-family: Roboto-SemiBold, Roboto;
      font-weight: 600;
      color: #000000;

      .right {
        display: flex;
        align-items: center;
      }

      .logo {
        margin-right: 10px;
        width: 20px;
      }
    }

    .transfer {
      transform: rotate(90deg);
      position: absolute;
      margin-top: 10px;
      margin-left: -13px;
      left: 50%;
      cursor: pointer;
    }

    .operate-part {
      width: 380px;
      padding: 20px;
      height: 100px;
      background: #ffffff;
      border-radius: 5px 5px 5px 5px;
      border: 1px solid #999999;
      margin-top: 30px;

      .input-header,
      .input-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 18px;
      }

      .input-box {
        position: relative;
        margin-top: 10px;
        overflow: hidden;
        :deep .ant-select-selector {
          background: #e6e9fb;
          font-size: 14px;
          font-family: Roboto-SemiBold, Roboto;
          font-weight: 600;
          color: #000000;
        }

        :deep .ant-select-selection--single {
          background: #e6e9fb;
        }

        input {
          flex: 1;
          border: none;
          font-size: 18px;
          font-family: Roboto-SemiBold, Roboto;
          font-weight: 600;
          width: auto;
          padding: 0 10px;

          &::placeholder {
            color: #c6c6c6;
          }
        }

        .set-max {
          cursor: pointer;
          width: 50px;
          position: absolute;
          right: 0px;
          height: 30px;
          font-size: 14px;
          justify-content: center;
          background: #e6e9fb;
          border-radius: 5px 5px 5px 5px;
          display: flex;
          align-items: center;
          color: #7887e4;
        }
      }
    }

    .info-row {
      font-size: 14px;
      font-family: Roboto-SemiBold, Roboto;
      font-weight: 600;
      color: #000000;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &:last-child {
        margin-top: 20px;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .TokenMove {
    width: 90%;
    .info {
      font-size: 14px;
    }
    .progress {
      transform: scale(0.8);
      white-space: nowrap;
    }

    .send-box {
      width: 100%;

      .input-header {
        font-size: 16px !important;
      }

      .set-max {
        padding: 0 10px;
        position: absolute;
        font-size: 12px !important;
        right: calc(30px + 5vw);
      }
      :deep .ant-select-selector {
        width: 100px;
        font-size: 12px;
      }

      .operate-part {
        width: 100%;

        .input-box {
          justify-content: space-between;
          overflow: hidden;

          .input {
            width: 100px;
            flex: 1;
          }
        }
      }

      .submit {
        width: 100%;
      }
    }

    .history-dialog-content {
      width: 90%;
      left: 5%;
      top: 10%;
      height: auto;
      padding-bottom: 20px;

      .table {
        width: 100%;
        overflow-x: auto;
      }

      .header {
        width: 600px !important;
      }

      .row-box {
        width: 600px;
      }
    }
  }
}
</style>
