import request from '../utils/request';

/*
    token move
 */
export function getTokenMoveInfo(data) {
    return request({
        url: 'api/token-move/config',
        method: 'get',
        params: data
    })
}
export function getMyTokenMoveLast(data) {
    return request({
        url: 'api/token-move/info',
        method: 'get',
        params: data
    })
}
export function getMyTokenMoveHistory(data) {
    return request({
        url: 'api/token-move/history',
        method: 'get',
        params: data
    })
}



