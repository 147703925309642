import { createStore } from 'vuex'
import Bridge from "@/store/Bridge";
import erc20 from "@/store/erc20";
export default createStore({
  state: {
    account:undefined,
    isMobile:false,
    web3:{},
    chainId:undefined,
    desireChainId:undefined,
    web3model: null
  },
  getters: {
  },
  mutations: {

    SET_ACCOUNT(state, account){
      state.account = account
    },
    set_ISMOBILE: (state, value) => {
      state.isMobile = value;
    },
    SET_Web3: (state, value) => {
      state.web3 = value;
    },
    Set_Web3modal(state, value){
      state.web3model = value;
    },
    SET_Chain: (state, value) => {
      state.chainId = value;
    },
    SET_DesireChain: (state, value) => {
      state.desireChainId = value;
    },
  },
  actions: {
  },
  modules: {
    Bridge,
    erc20
  }
})
